import React, { Component } from "react";
import "../styles/footer.scss";

export default class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            contact: null
        }

        this.key = 0;
    }

    componentDidMount() {
        document.documentElement.dataset.routing = 0;

        fetch("/data/footer.json").then((r) => {
            r.json().then((r) => this.setState({ data: r.data }));
        });

        fetch("/data/contact.json").then((r) => {
            r.json().then((r) => this.setState({ contact: r.data.firm }));
        });
    }

    renderSocial = (item) => {
        this.key++;

        return(<a key={ this.key } href={ item.url ? item.url : null } className="item"><div className={ "icon fa fa-" + item.icon } />{ item.name }</a>)
    }

    renderLink = (item) => {
        this.key++;

        return(<div key={ this.key } className="item"><a href={ item.url } className="link">{ item.name }</a></div>)
    }

    render() {
        return(
            <div className="footer">
                <div className="grid">
                    <div className="cell">
                        <div className="title">Sosyal</div>
                        <div className="line" />

                        <div className="list">
                            { this.state.data ? this.state.data.social.map((i) => this.renderSocial(i)) : null }
                        </div>
                    </div>

                    <div className="cell">
                        <div className="title">Bağlantılar</div>
                        <div className="line" />

                        <div className="list">
                            { this.state.data ? this.state.data.links.map((i) => this.renderLink(i)) : null }
                        </div>
                    </div>

                    <div className="cell">
                        <div className="title">İletişim</div>
                        <div className="line" />

                        <div className="list">
                            <div className="item">
                                <div className="text"><span className="icon fa fa-envelope-o" />{ this.state.contact ? this.state.contact[2].name + ":" : null }</div>
                                <div className="text">{ this.state.contact ? this.state.contact[2].value : null }</div>
                            </div>

                            <div className="item">
                                <div className="text"><span className="icon fa fa-phone" />{ this.state.contact ? this.state.contact[1].name + ":" : null }</div>
                                <div className="text">{ this.state.contact ? this.state.contact[1].value : null }</div>
                            </div>
                        </div>
                    </div>

                    <div className="cell">
                        <div className="title">Hakkımızda</div>
                        <div className="line" />

                        <div className="text">{ this.state.data ? this.state.data.about : null }</div>
                    </div>
                </div>

                <div className="bottom">
                    <div className="left">
                        <img className="logo" src="/assets/logo-white.png" alt="Alaska Teknoloji" onClick={ () => window.scrollTo({ top: 0, behavior: "smooth" }) } />
                    </div>

                    <div className="right">
                        { this.state.data ? this.state.data.owner + " © " + this.state.data.year : null }
                    </div>
                </div>
            </div>
        )
    }
}