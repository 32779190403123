import React, { Component } from "react";
import Rellax from "rellax";
import "../styles/head.scss";

export default class Head extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null
        }
    }

    componentDidMount() {
        fetch("/data/head.json").then((r) => {
            r.json().then((r) => this.setState({ data: r.data }));
        });

        const speed = -10;

        new Rellax("#clouds", { speed: speed * 1.5, center: false, round: true });
        new Rellax("#mountainBack", { speed: speed, center: false, round: true });
    }

    goProducts = () => {
        const offset = document.getElementById("navbar").offsetHeight + 45;
        const top = document.getElementById("promote").offsetTop - offset;
    
        window.scrollTo({ top, behavior: "smooth" });
    }

    render() {
        return(
            <div className="head">
                <div className="welcome">
                    <div className="welcomeContainer">
                        <div className="left">
                            <div />
                        </div>
            
                        <div className="middle">
                            <div className="line" />
                        </div>
            
                        <div className="right">
                            <div className="title">{ this.state.data ? this.state.data.title : null }</div>
                            <div className="text">{ this.state.data ? this.state.data.text : null }</div>
                            
                            <div className="button" onClick={ () => this.goProducts() }>{ this.state.data ? this.state.data.button : null }</div>
                        </div>
                    </div>
                </div>
        
                <div className="mountain">
                    <svg viewBox="0 0 1919.63 552.24">
                        <g transform="translate(-0.37 -496)">
                            <g id="clouds">
                                <g className="fly">
                                    <g transform="translate(219 570)">
                                        <path className="c2" d="M334.452,202.533A45.441,45.441,0,0,0,259.7,218.666L343.16,214.8a45.355,45.355,0,0,0-8.708-12.266Z" transform="translate(550 -113.159)"/>
                                        <path className="c2" d="M514.452,135.533A45.441,45.441,0,0,0,439.7,151.666L523.16,147.8A45.354,45.354,0,0,0,514.452,135.533Z" transform="translate(499 -85.159)"/>
                                        <path className="c2" d="M831.452,302.533a45.441,45.441,0,0,0-74.75,16.133L840.16,314.8A45.357,45.357,0,0,0,831.452,302.533Z" transform="translate(341 -198.159)"/>
                                    </g>
                                </g>
                            </g>
        
                            <g id="mountainBack">
                                <g transform="translate(-952.425 205.477)">
                                    <path className="c1" d="M1118.425,750.725H59.547L341,198.725l62.95,161.62,20.09,51.59,55.37-75.98L508,296.725l130.2,149.27,36.8-81.27,254.78,207.92,14.21,11.59,14.38,11.74,4.58,3.74Z" transform="translate(1129 91.798)"/>
                                </g>
                            </g>
        
                            <g>
                                <g className="flyM1">
                                    <path className="c2" d="M846.806,537.548H159.71L244.72,445.4,401.783,261.17l10.585-12.415L554.443,82.1l98.308,155.7,2.574,4.075L780.392,439.934Z" transform="translate(-159.34 509.994)"/>
                                    <path className="c3" d="M600.353,237.8l-15,26.356-33.636-35.238L514.863,264.16l-20.83-40.045L460.385,264.16l-9.614-25.624-22.42,25.624-40.057-25.624L359.97,248.756,502.045,82.1Z" transform="translate(-106.942 509.994)"/>
                                </g>
        
                                <g className="flyM2">
                                    <path className="c2" d="M1075.735,504.5,906.022,304.253l-83.2-98.163L742.412,306.912l-73.345,91.993L585.18,504.086" transform="translate(102 543.435)"/>
                                    <path className="c3" d="M873.414,304.253,831.87,355.087l-33.644-40.052L746.958,356.69,709.8,306.912,790.215,206.09Z" transform="translate(134.608 543.435)"/>
                                </g>
        
                                <g className="flyM3">
                                    <path className="c2" d="M742.926,665.882,548.408,474.218l-48.238-47.533L356.191,284.822,177.713,475.351,0,665.085" transform="translate(1177.074 382.358)"/>
                                    <path className="c3" d="M396.046,426.685l-85.713,57.468-101.2-39.866L73.589,475.351,252.067,284.822Z" transform="translate(1281.198 382.358)"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        )
    }
}