import React, { Component } from "react";
import "../styles/main.scss";

import Head from "../components/head";
import Footer from "../components/footer";

import Promote from "../components/promote";
import Products from "../components/products";
import Services from "../components/services";

export default class Home extends Component {
    render() {
        return(
            <div>
                <Head />

                <div className="nest home">
                    <Promote />
                    <Products />
                    <Services />
                </div>

                <Footer />
            </div>
        )
    }
}