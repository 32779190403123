import React, { Component } from "react";
import "../styles/products.scss";

export default class Products extends Component {
    constructor(props) {
        super(props);

        this.state = {
            products: null
        }
    }

    componentDidMount() {
        fetch("/data/products.json").then((r) => {
            r.json().then((r) => this.setState({ products: r.data }));
        });
    }

    renderProduct = (item) => {
        const renderButton = () => {
            if(item.url === "") {
                return(<div className="button" onClick={ () => document.getElementById("nav-contact").click() }><div className="icon fa fa-phone" />Bilgi Alın</div>)
            } else {
                return(<a href={ item.url } className="button"><div className="icon fa fa-external-link" />Detaylar</a>)
            }
        }

        return(
            <div key={ item.id } className="item">
                <div className="cover">
                    <img src={ "/assets/products/" + item.id + ".jpg" } alt={ item.name }></img>
                </div>
    
                <div className="info">
                    <div className="infoTitle">{ item.name }</div>
                    <div className="infoDesc">{ item.desc }</div>
                </div>
    
                { renderButton() }
            </div>
        )
    }

    render() {
        return(
            <div className="products section">
                <div className="title">Ürünler</div>
                <div className="line" />

                <div className="grid">
                    { this.state.products ? this.state.products.map((i) => this.renderProduct(i)) : null }
                </div>
            </div>
        )
    }
}