import React, { Component } from "react";
import "../styles/promote.scss";

export default class Promote extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null
        }
    }

    componentDidMount() {
        fetch("/data/promote.json").then((r) => {
            r.json().then((r) => this.setState({ data: r.data }));
        });
    }

    goServices = () => {
        const offset = document.getElementById("navbar").offsetHeight + 45;
        const top = document.getElementById("services").offsetTop - offset;
    
        window.scrollTo({ top, behavior: "smooth" });
    }

    render() {
        return(
            <div id="promote" className="section">
                <div className="promote">
                    <div className="tint"  />
                    <div className="content">
                        <div className="text">{ this.state.data ? this.state.data.text : null }</div>
                        <div className="button" onClick={ () => this.goServices() }>{ this.state.data ? this.state.data.button : null }</div>
                    </div>
                </div>
            </div>
        )
    }
}