import React, { Component } from "react";
import "../styles/services.scss";

export default class Services extends Component {
    constructor(props) {
        super(props);

        this.state = {
            services: null
        }

        this.key = 0;
    }

    componentDidMount() {
        fetch("/data/services.json").then((r) => {
            r.json().then((r) => this.setState({ services: r.data }));
        });
    }

    renderService = (item) => {
        this.key++;

        return(
            <div key={ this.key } className="item">
                <div className="serviceHead">
                    <div className="left">
                        <i className={ "fa fa-" + item.icon }></i>
                    </div>

                    <div className="right">
                        <div className="serviceTitle">{ item.title }</div>
                    </div>
                </div>

                <div className="desc">{ item.desc }</div>
            </div>
        )
    }

    render() {
        return(
            <div id="services" className="services section">
                <div className="title">Hizmetler</div>
                <div className="line" />

                <div className="grid">
                    { this.state.services ? this.state.services.map((i) => this.renderService(i)) : null }
                </div>
            </div>
        )
    }
}